@mixin flex-direction($values) {
	-webkit-flex-direction: $values;
	flex-direction: $values;
}

@mixin flex-flow($values) {
	-webkit-flex-flow: $values;
	flex-flow: $values;
}

@mixin align-items($values) {
	-webkit-align-items: $values;
	align-items: $values;
}

@mixin justify-content($values) {
	-webkit-justify-content: $values;
	justify-content: $values;
}

@mixin flex($values) {
	-webkit-flex: $values;
	flex: $values;
}

@mixin display-flex() {
	display: -webkit-flex;
	display: flex;
}

@mixin display-inline-flex() {
	display: -webkit-inline-flex;
	display: inline-flex;
}
