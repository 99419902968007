$baseurl: "";
$body-background-color: #2B2E31;

$content-max-width: 800px;
$content-color: #222;
$content-background-color: #fff;

$brand-colour: #3583d6;
$brand-colour-light: mix($brand-colour, $content-background-color, 10%);

$nav-header-background-color: $brand-colour;
$nav-header-height: 60px;
$nav-background-color: #f5f5f5;
$nav-width: 300px;

$space: 20px;

$mobile-break: 700px;
$full-width-break: $nav-width + ($space * 4) + $content-max-width;

@import "mixins";
@import "normalize";
@import "pygments";
@import "typography";
@import "code";
@import "tables";
@import "layout";
